import { Link } from 'react-router';
import { useDecision } from '@optimizely/react-sdk';
import { ClientOnly } from 'remix-utils/client-only';
import HelloWebsiteIcon from '~/assets/images/hello-website-icon.png';
import HelloEleventyIcon from '~/assets/images/hello-eleventy-icon.png';
import HelloNodeIcon from '~/assets/images/hello-node-icon.png';
import HelloReactIcon from '~/assets/images/hello-react-icon.png';
import HelloSqliteIcon from '~/assets/images/hello-sqlite-icon.png';
import ExternalProjectIcon from '~/assets/images/external-site-icon.png';
import GithubIcon from '~/assets/images/github-icon.png';

import './new-project-dropdown.css';

const LinkWrapper = ({
  clientOnly = false,
  onClick,
  href,
  children,
}: {
  // ClientOnly is only needed if a link is rendered conditionally based on an Optimizely experiment because of hydration issues with SSR
  clientOnly?: boolean;
  onClick?: () => void;
  href?: string;
  children: React.ReactNode;
}) => {
  let component;

  if (!href) {
    component = <button onClick={onClick}>{children}</button>;
  } else {
    component = (
      <Link to={href} onClick={onClick}>
        {children}
      </Link>
    );
  }

  if (clientOnly) {
    <ClientOnly>{() => component}</ClientOnly>;
  }

  return component;
};

const NewProjectDropdown = () => {
  const [decision] = useDecision('share_external_sites');
  const shareExternalProject = decision.enabled;
  const newProjectLinks = [
    ...(shareExternalProject
      ? [
          {
            title: 'Add a site to Glitch',
            description:
              'Share a project from another site on your Glitch profile.',
            href: '/external-project',
            icon: ExternalProjectIcon,
            clientOnly: true,
          },
        ]
      : []),
    {
      title: 'glitch-hello-website',
      href: 'https://glitch.com/edit/#!/remix/glitch-hello-website',
      description: 'Your very own basic web page, ready for you to customize.',
      icon: HelloWebsiteIcon,
    },
    {
      title: 'glitch-hello-node',
      href: 'https://glitch.com/edit/#!/remix/glitch-hello-node',
      description:
        'A simple Node app built with Fastify, instantly up and running.',
      icon: HelloNodeIcon,
    },
    {
      title: 'glitch-hello-react',
      href: 'https://glitch.com/edit/#!/remix/glitch-hello-react',
      description: 'Get started with a new React project on Glitch!',
      icon: HelloReactIcon,
    },
    {
      title: 'glitch-hello-eleventy',
      href: 'https://glitch.com/edit/#!/remix/glitch-hello-eleventy',
      description: 'Build a new Eleventy blog on Glitch! ',
      icon: HelloEleventyIcon,
    },
    {
      title: 'glitch-hello-sqlite',
      href: 'https://glitch.com/edit/#!/remix/glitch-hello-sqlite',
      description: 'Use a persistent SQLite database with your Node.js app.',
      icon: HelloSqliteIcon,
    },

    {
      title: 'Import from Github',
      description: 'Import a project into the Glitch editor from Github.',
      onClick: () => alert("This isn't hooked up yet but just you wait!"),
      icon: GithubIcon,
    },
    {
      title: 'Find more starters 👉🏾',
      href: '/discover',
    },
  ];
  return (
    <ul>
      {newProjectLinks.map((item) => (
        <li key={item.title}>
          <LinkWrapper
            href={item.href}
            onClick={item.onClick}
            clientOnly={item.clientOnly}
          >
            <span className="new-project-title">
              {item.icon && (
                <img className="new-project-icon" src={item.icon} alt="" />
              )}
              {item.title}
            </span>
            {item.description && (
              <span className="new-project-description">
                {item.description}
              </span>
            )}
          </LinkWrapper>
        </li>
      ))}
    </ul>
  );
};

export default NewProjectDropdown;
