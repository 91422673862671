import { Link } from 'react-router';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import DropdownMenu from './DropdownMenu';
import LogoIcon from './LogoIcon';
import UserAccountDropdown from './UserAccountDropdown';
import type { User } from '~/models/user.server';
import type { Team } from '~/models/team.server';
import { IconHamburger } from '@fastly/beacon-icons';
import NewProjectDropdown from './NewProjectDropdown';

import './header.css';

export type NavItemType = {
  title: string;
  href: string;
  analytics: string;
  dropdown?: {
    trigger: string;
    analytics: string;
    content: React.ReactNode;
  };
};

const accountNav = [
  {
    title: 'Sign up',
    href: '/signup',
    analytics: 'sign-up',
  },
  {
    title: 'Log in',
    href: '/login',
    analytics: 'log-in',
  },
];

export default function Header({
  user,
  teams,
}: {
  user?: User;
  teams?: Team[];
}): JSX.Element {
  const newProjectNav = {
    title: 'New Project',
    href: '/discover',
    analytics: 'new-project',
    dropdown: {
      trigger: 'New Project',
      analytics: 'new-project-dropdown',
      content: <NewProjectDropdown />,
    },
  };

  const mainNavLoggedIn = [
    {
      title: 'Dashboard',
      href: '/dashboard/projects',
      analytics: 'dashboard',
    },
    { ...newProjectNav },
    {
      title: 'Pricing',
      href: 'https://glitch.com/pricing',
      analytics: 'pricing',
    },
    {
      title: 'Search',
      href: '/search',
      analytics: 'search',
    },
    {
      title: 'Help Center',
      href: 'https://help.glitch.com',
      analytics: 'help',
    },
    {
      title: 'Forum',
      href: 'https://support.glitch.com/',
      analytics: 'forum',
    },
  ];

  const mainNav = [
    { ...newProjectNav },
    {
      title: 'Pricing',
      href: 'https://glitch.com/pricing',
      analytics: 'pricing',
    },
    {
      title: 'Search',
      href: '/search',
      analytics: 'search',
    },
    {
      title: 'Help Center',
      href: 'https://help.glitch.com',
      analytics: 'help',
    },
    {
      title: 'Forum',
      href: 'https://support.glitch.com/',
      analytics: 'forum',
    },
  ];
  const mainNavLinks: NavItemType[] = user ? mainNavLoggedIn : mainNav;
  return (
    <header className="header" data-analytics-location="site-navigation">
      <div className="header-wrapper">
        <div className="header-main-nav">
          <div className="header-logo">
            <Link to="/" aria-label="Glitch homepage" data-analytics="logo">
              <LogoIcon />
            </Link>
          </div>
          <NavigationMenu.Root>
            <NavigationMenu.List className="header-links header-large-screens">
              {mainNavLinks.map((item) => {
                if (item?.dropdown) {
                  return (
                    <NavigationMenu.Item key={item.title}>
                      <NavigationMenu.Trigger
                        // Do not open/close the menu on hover
                        onPointerMove={(event) => event.preventDefault()}
                        onPointerLeave={(event) => event.preventDefault()}
                        data-analytics={item.dropdown.analytics}
                      >
                        {item.dropdown.trigger}
                      </NavigationMenu.Trigger>
                      <NavigationMenu.Content className="header-dropdown">
                        {item.dropdown.content}
                      </NavigationMenu.Content>
                    </NavigationMenu.Item>
                  );
                }
                return (
                  <NavigationMenu.Item key={item.href}>
                    <Link data-analytics={item.analytics} to={item.href}>
                      {item.title}
                    </Link>
                  </NavigationMenu.Item>
                );
              })}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
        {!user && (
          <>
            <div className="header-links header-large-screens header-account-nav">
              {accountNav.map((item) => (
                <Link
                  data-analytics={item.analytics}
                  key={item.href}
                  to={item.href}
                >
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="header-mobile">
              <DropdownMenu
                trigger={<IconHamburger width="2rem" />}
                items={[...mainNavLinks, ...accountNav]}
              />
            </div>
          </>
        )}
        {user && (
          <UserAccountDropdown
            // When we have a user, we want to put the main navidation links into the user nav on mobile
            additionalLinks={mainNavLinks.map((link) => {
              return { ...link, mobileOnly: true };
            })}
            avatarThumbnailUrl={user?.avatarThumbnailUrl}
            login={user?.login}
            name={user?.name}
            teams={teams}
          />
        )}
      </div>
    </header>
  );
}
