import { Link } from 'react-router';
import type { LinkItem } from '../DropdownMenu';
import DropdownMenu from '../DropdownMenu';
import type { User } from '~/models/user.server';
import type { Team } from '~/models/team.server';
import { getTeamLink, getTeamAvatarUrl } from '../../../../utils/team';
import { IconArrowRight, IconChevronDown } from '@fastly/beacon-icons';

import './user-accout-dropdown.css';

type UserProps = Pick<User, 'avatarThumbnailUrl' | 'login' | 'name'>;

interface UserAccountProps extends UserProps {
  teams?: Team[];
  additionalLinks?: LinkItem[];
}

const accountLinks = [
  {
    title: 'Account Settings',
    href: '/account-settings',
    analytics: 'account-settings',
  },
  {
    title: 'Service Status',
    href: 'https://status.glitch.com/',
    analytics: 'status',
  },
];

const UserAccountDropdown = ({
  avatarThumbnailUrl,
  login,
  name,
  teams,
  additionalLinks = [],
}: UserAccountProps) => {
  const items = [
    <Link
      key="login"
      to={`/user/${login}`}
      className="account-info account-profile-link"
      data-analytics="user-profile"
    >
      <div className="account-avatar-container">
        <img
          data-testid="avatar"
          className="account-avatar"
          src={avatarThumbnailUrl || ''}
          alt=""
        />
      </div>
      <div className="account-details">
        <span className="account-name">{name}</span>
        <span className="account-login">@{login}</span>
      </div>
    </Link>,
    ...additionalLinks,
    ...accountLinks,
    <div key="teams" className="account-teams">
      <strong>Teams</strong>
      <ul className="teams-list">
        {teams?.map((team) => (
          <li key={team.id}>
            <Link
              className="teams-list-link"
              to={getTeamLink({ url: team.url })}
              data-analytics="team-profile"
            >
              <img
                src={getTeamAvatarUrl({
                  id: team.id,
                  hasAvatarImage: team.hasAvatarImage,
                  updatedAt: team.updatedAt,
                })}
                alt=""
              />
              <span>{team.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <Link className="button-secondary" to="/team/create">
        Create a team <IconArrowRight />
      </Link>
    </div>,
    <Link
      data-analytics="sign-out"
      key="sign-out"
      to="/logout"
      className="button full-width"
    >
      Sign out
    </Link>,
  ];
  return (
    <DropdownMenu
      trigger={
        <div className="account-trigger" data-analytics="main-dropdown">
          <IconChevronDown />
          <div className="account-avatar-container">
            <img
              data-testid="avatar"
              className="account-avatar"
              src={avatarThumbnailUrl || ''}
              alt=""
            />
          </div>
        </div>
      }
      items={items}
    />
  );
};

export default UserAccountDropdown;
