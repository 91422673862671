import * as RadixToast from '@radix-ui/react-toast';
import './toast.css';
import { useToast } from '~/context/toast-context';

export type UpdateToastType = (message: string) => void;

const Toast = () => {
  const { toastTitle, toastOpen, setToastOpen } = useToast();

  return (
    <RadixToast.Provider swipeDirection="right">
      <RadixToast.Root
        className="toast-root"
        open={toastOpen}
        onOpenChange={setToastOpen}
      >
        <RadixToast.Title
          dangerouslySetInnerHTML={{ __html: toastTitle }}
        ></RadixToast.Title>
      </RadixToast.Root>
      <RadixToast.Viewport className="toast" />
    </RadixToast.Provider>
  );
};

export default Toast;
