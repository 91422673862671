import { Link } from 'react-router';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import './dropdown-menu.css';

export type LinkItem = {
  href: string;
  title: string;
  analytics?: string;
  mobileOnly?: boolean;
};

interface DropdownMenuProps {
  /* Element used to trigger the menu */
  trigger: React.ReactNode;
  /* React nodes or links that render within the navigation */
  items: (React.ReactNode | LinkItem)[];
}

const isLink = (item: LinkItem | React.ReactNode): item is LinkItem => {
  return (item as LinkItem).href !== undefined;
};

const DropdownMenu = ({ trigger, items }: DropdownMenuProps) => {
  return (
    <NavigationMenu.Root delayDuration={0} className="dropdown-root">
      <NavigationMenu.List className="dropdown-list">
        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            // Do not open/close the menu on hover
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
            className="dropdown-trigger"
            aria-label="Open navigation"
          >
            {trigger}
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="dropdown-content">
            <ul className="dropdown-items">
              {items.map((item, index) => {
                if (isLink(item)) {
                  return (
                    <li
                      key={item.title}
                      className={`dropdown-link ${item.mobileOnly ? 'mobile-only' : ''}`}
                    >
                      <Link
                        key={item.href}
                        to={item.href}
                        data-analytics={item.analytics}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} data-analytics={item.analytics}>
                      {item}
                    </li>
                  );
                }
              })}
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

export default DropdownMenu;
